/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/cash-dom@4.1.5/dist/cash.min.js
 * - /npm/colcade@0.2.0/colcade.min.js
 * - /npm/feather-icons@4.28.0/dist/feather.min.js
 * - /npm/lozad@1.16.0/dist/lozad.min.js
 * - /npm/fuse.js@3.4.5/dist/fuse.min.js
 * - /npm/imask@5.2.1/dist/imask.min.js
 * - /npm/dropzone@5.7.0/dist/dropzone.min.js
 * - /npm/video.js@7.10.1/dist/video.min.js
 * - /npm/vue@2.6.12/dist/vue.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
